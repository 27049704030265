"use client";

import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

import Link from "next/link";
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import MuiLink from "@mui/material/Link";
import colors from "@/lib/palette";
import { Button } from "@/shared/components";
import AuthDataApi from "../api/auth.api";
import { AuthType, SignUpForm } from "../types";

type Props = {
  email: string;
  setEmail: (email: string) => void;
  setAuthType: (authType: AuthType) => void;
  handleClose: () => void;
};

const formSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address"),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, and one number"
    ),
  attach_mailing: z.boolean().optional(),
});

const SignUp = ({ setEmail, setAuthType }: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors, isValid },
  } = useForm<SignUpForm>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  const handleFormSubmit: SubmitHandler<SignUpForm> = async (form) => {
    setLoading(true);
    try {
      await AuthDataApi.signUp(form);

      toast.success("Account created successfully");

      setAuthType("signUpConfirmation");
      setEmail(form.email);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center" marginBottom={4}>
        Create an account
      </Typography>

      <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <TextField
            placeholder="First Name*"
            variant="filled"
            {...register("first_name")}
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
            disabled={loading}
          />

          <TextField
            placeholder="Last Name*"
            variant="filled"
            {...register("last_name")}
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
            disabled={loading}
          />

          <TextField
            placeholder="Email*"
            variant="filled"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            disabled={loading}
          />

          <TextField
            placeholder="Password*"
            variant="filled"
            type="password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            disabled={loading}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox {...register("attach_mailing")} disabled={loading} />
          }
          label={
            <Typography component="span" variant="body2">
              Add me to the mailing list
            </Typography>
          }
          sx={{
            margin: "24px 0 0",
            marginLeft: "-11px",
          }}
        />

        <Button
          type="submit"
          disabled={loading || !isValid}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Create an account
        </Button>
      </Box>

      <Typography variant="body2" textAlign="center" sx={{ mt: "32px" }}>
        By proceeding, you agree to our &nbsp;
        <Link href="/terms-and-conditions" style={{ color: colors.blue }}>
          Terms of Use
        </Link>
        &nbsp; and &nbsp;
        <Link href="/privacy-policy" style={{ color: colors.blue }}>
          Privacy Policy
        </Link>
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        sx={{
          mt: "32px",
        }}
      >
        <Typography variant="body2">Already have an account?</Typography>

        <MuiLink
          component="button"
          variant="body2"
          sx={{
            color: colors.blue,
            fontWeight: 700,
            textDecoration: "none",
          }}
          onClick={() => setAuthType("signIn")}
        >
          Sign in
        </MuiLink>
      </Stack>
    </Box>
  );
};

export default SignUp;
