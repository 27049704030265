import React from "react";
import { Box } from "@mui/material";
import { Icon, Button } from "@/shared/components";

import colors from "@/lib/palette";
import SearchFeature from "@/shared/features/search";

type Props = {
  open: boolean;
  onClose: () => void;
};

function MobileSearchMenuComponent({ open, onClose }: Props) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
          height: "76px",
          top: 0,
          left: 0,
          px: "20px",
          backgroundColor: colors.white,
          zIndex: 101,
          transform: open ? "translateY(0)" : "translateY(-100%)",
          transition: "transform 0.2s ease-in-out",
        }}
      >
        <Button
          type="button"
          aria-label="search"
          variant="outlined-primary"
          sx={{
            borderRadius: "50%",
            border: "1px solid #C9D3FF",
            width: {
              xs: "36px",
              xl: "48px",
            },
            height: {
              xs: "36px",
              xl: "48px",
            },
          }}
        >
          <Icon
            icon="search"
            color={colors.blue}
            sx={{
              width: {
                xs: "16px",
                xl: "28px",
              },
              height: {
                xs: "16px",
                xl: "28px",
              },
            }}
          />
        </Button>

        <SearchFeature variant="secondary" sx={{ flex: 1, mx: 2 }} />

        <Button
          height={"32px"}
          width={"32px"}
          variant="outlined-secondary"
          sx={{
            border: "none",
          }}
          onClick={onClose}
        >
          <Icon icon={"close"} width={"32px"} height={"32px"} />
        </Button>
      </Box>

      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 99,
          display: open ? "block" : "none",
        }}
        onClick={onClose}
      />
    </>
  );
}

export default MobileSearchMenuComponent;
