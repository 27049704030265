"use client";
import React, { useState } from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { toast } from "react-toastify";

import OtpInput from "react-otp-input";

import colors from "@/lib/palette";

import { Button } from "@/shared/components";

import AuthDataApi from "../api/auth.api";
import { AuthType, ConfirmForm } from "../types";

import "./index.css";

type Props = {
  title: string;
  email: string;
  setEmail: (email: string) => void;
  backToAuthType: AuthType;
  setAuthType: (authType: AuthType) => void;
  setOtpCode: (otpCode: string) => void;
};

export default function Confirm(props: Props) {
  const { title, email, setAuthType, setOtpCode, backToAuthType } = props;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<ConfirmForm>({
    email,
    otp_code: "",
  });

  const handleChange = (value: string) => {
    setForm({
      ...form,
      otp_code: value,
    });
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    setLoading(true);
    try {
      await AuthDataApi.activateAccount(form);

      if (backToAuthType === "signUp") {
        setAuthType("signIn");
        toast.success("Your account has been confirmed");
      } else {
        setAuthType("setNewPassword");
        toast.success("OTP code confirmed");
        setOtpCode(form.otp_code);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    setLoading(true);
    try {
      await AuthDataApi.resetOtpCode({
        email: form.email,
        action: "profile_activation",
      });

      toast.success("We've sent you a new confirmation code");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={1}
      >
        We have sent you a confirmation code to {email}
        <br />
        Enter code below to proceed
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <OtpInput
            shouldAutoFocus
            containerStyle="containerStyle"
            inputStyle="inputStyle"
            value={form.otp_code}
            inputType="tel"
            onChange={handleChange}
            numInputs={4}
            renderInput={(props) => <input required {...props} />}
          />
        </Box>

        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Confirm request
        </Button>
      </Box>

      <Button
        variant="outlined-primary"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType(backToAuthType)}
      >
        Back to website
      </Button>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        sx={{
          mt: "32px",
        }}
      >
        <Typography variant="body2">Didn’t receive the email?</Typography>

        <Link
          component="button"
          variant="body2"
          sx={{
            color: colors.blue,
            fontWeight: 700,
            textDecoration: "none",
          }}
          onClick={handleResend}
        >
          Click to resend
        </Link>
      </Stack>
    </Box>
  );
}
