import httpClient from "@/lib/http-client";
// import { UserType } from "@/store/user.store";

import {
  AccountSettingsType,
  GetUserDate,
  UpdateUserPasswordMethodType,
} from "./user.types.d";
import { UserType } from "@/store/user.store";

export const getUserInfo: GetUserDate = async () => {
  return httpClient.get("/user/profile/me");
};

export const getCompanyInfo: GetUserDate = async () => {
  return httpClient.get("/company-manager/profile/me");
};

export const updateUserPassword: UpdateUserPasswordMethodType = async (
  form
) => {
  return httpClient.post("/user/password", form);
};

export const updateCompanyData = async (
  form: AccountSettingsType
): Promise<{
  data: UserType;
  status: string;
  detail: {
    job_title?: string;
    company_name?: string;
  };
}> => {
  return httpClient.patch("/update-manager-profile", form);
};

export const updateUserData = async (
  form: AccountSettingsType
): Promise<{
  data: UserType;
  status: string;
  detail: {
    job_title?: string;
    company_name?: string;
  };
}> => {
  return httpClient.patch("/update-profile", form);
};

export const logout = async () => {
  return httpClient.get("/user/logout");
};

export const deleteAccount = async () => {
  return httpClient.post("/delete-profile");
};

const methods = {
  getUserInfo,
  getCompanyInfo,
  logout,
  updateCompanyData,
  updateUserData,
  updateUserPassword,
  deleteAccount,
};

export default methods;
