import colors from "@/lib/palette";
import { Button, Icon } from "@/shared/components";
import { Box } from "@mui/material";
import React from "react";

type Props = {
  openBurgerMenu: boolean;
  onOpenSearchMenu: () => void;
  onToggleMenu: () => void;
};

function MobileButtonsComponent({
  openBurgerMenu,
  onToggleMenu,
  onOpenSearchMenu,
}: Props) {
  return (
    <Box
      component="nav"
      display="flex"
      alignItems={"center"}
      columnGap={4}
      marginLeft={{
        xs: "auto",
      }}
    >
      <Button
        type="button"
        aria-label="search"
        variant="outlined-primary"
        sx={{
          borderRadius: "50%",
          border: "1px solid #C9D3FF",
          width: {
            xs: "36px",
            xl: "48px",
          },
          height: {
            xs: "36px",
            xl: "48px",
          },
        }}
        onClick={onOpenSearchMenu}
      >
        <Icon
          icon="search"
          color={colors.blue}
          sx={{
            width: {
              xs: "16px",
              xl: "28px",
            },
            height: {
              xs: "16px",
              xl: "28px",
            },
          }}
        />
      </Button>
      <Button
        height={"32px"}
        width={"32px"}
        variant="outlined-secondary"
        sx={{
          border: "none",
        }}
        onClick={onToggleMenu}
      >
        <Icon
          icon={openBurgerMenu ? "close" : "menu"}
          width={"32px"}
          height={"32px"}
        />
      </Button>
    </Box>
  );
}

export default MobileButtonsComponent;
