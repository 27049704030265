"use client";

import { useState } from "react";
import { z } from "zod";
import { setCookie } from "cookies-next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { useAtom } from "jotai";

import Client from "@/lib/http-client";
import { StoreType, userStore } from "@/store/user.store";

import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
} from "@mui/material";
import { Button } from "@/shared/components";

import colors from "@/lib/palette";

import { AuthType, SignInForm } from "../types";
import AuthApiData from "../api/auth.api";
import { OptionsType } from "cookies-next/lib/types";

type Props = {
  email: string;
  setEmail: (email: string) => void;
  setAuthType: (authType: AuthType) => void;
  handleClose: () => void;
};

const formSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, and one number"
    ),
  rememberMe: z.boolean().optional(),
});

const SignUp = ({ setAuthType, setEmail, handleClose }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUser] = useAtom<StoreType>(userStore);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>({
    resolver: zodResolver(formSchema),
  });

  const handleFormSubmit: SubmitHandler<SignInForm> = async (form) => {
    setLoading(true);

    try {
      const { data } = await AuthApiData.signIn(form);

      setLoading(false);

      toast.success("You have successfully logged in");

      const cookieOptions: OptionsType = {
        path: "/",
        sameSite: "strict",
        secure: true,
        expires: form.rememberMe
          ? new Date(Date.now() + 60 * 60 * 24 * 30)
          : new Date(Date.now() + 60 * 60 * 24 * 1),
        maxAge: form.rememberMe ? 60 * 60 * 24 * 30 : 60 * 60 * 24 * 1,
      };

      setCookie("token", data.access_token, cookieOptions);

      setCookie("x-access-resource", data["x-access-resource"], cookieOptions);

      Client.setAccessToken(data.access_token);
      Client.setAccessResource(data["x-access-resource"]);

      setUser({
        user: {
          email: data.email,
          first_name: data.first_name,
          id: data.id,
          last_name: data.last_name,
          role: data.role,
          status: data.status,
          job_title: data.job_title,
          company_name: data.company_name,
        },
        isAuth: true,
      });

      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Please activate your account.") {
          setEmail(form.email);
          setAuthType("ActiveYourAccount");
        } else {
          toast.error(error.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <TextField
            placeholder="Email*"
            variant="filled"
            type="email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            disabled={loading}
          />

          <TextField
            placeholder="Password*"
            variant="filled"
            type="password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            disabled={loading}
          />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            margin: "24px 0",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox {...register("rememberMe")} disabled={loading} />
            }
            label={
              <Typography component="span" variant="body2">
                Remember Me
              </Typography>
            }
          />

          <Link
            component="span"
            variant="body2"
            sx={{
              color: colors.blue,
              fontWeight: 700,
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => setAuthType("resetPassword")}
          >
            Forgot Password?
          </Link>
        </Stack>

        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Sign in
        </Button>
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        sx={{
          mt: "32px",
        }}
      >
        <Typography variant="body2">Don’t have an account?</Typography>

        <Link
          component="button"
          variant="body2"
          sx={{
            color: colors.blue,
            fontWeight: 700,
            textDecoration: "none",
          }}
          onClick={() => setAuthType("signUp")}
        >
          Create
        </Link>
      </Stack>
    </Box>
  );
};

export default SignUp;
