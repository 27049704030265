import UserApi from "./user.api";

import type {
  AccountSettingsType,
  ChangePasswordType,
  GetUserDate,
  UpdateUserPasswordMethodType,
} from "./user.types";

export type {
  AccountSettingsType,
  ChangePasswordType,
  GetUserDate,
  UpdateUserPasswordMethodType,
};

export default UserApi;
