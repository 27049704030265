import httpClient from "@/lib/http-client";

import {
  // AuthResponse,
  ConfirmtMethod,
  RegisterMethod,
  // ResetPasswordForm,
  // SignInForm,
  SignInMethod,
  SignUpForm,
  // ConfirmForm,
  ResetPasswordMethod,
  CreateNewPasswordMethod,
  // CreateNewPasswordForm,
  LogooutMethod,
  ResetOtpCodeMethodType,
  // ResesOtpCodeForm,
} from "./../types.d";

// import { UserType } from "@/lib/store/user.store";

export const signUp: RegisterMethod = async (form) => {
  return httpClient.post("/user/register", form);
};
// AuthResponse<
//   UserType & { access_token: string; "x-access-resource": string }
// >

export const signIn: SignInMethod = async (form) => {
  return httpClient.post("/user/login", form);
};

export const activateAccount: ConfirmtMethod = async (form) => {
  return httpClient.post("/activate-profile", form);
};

export const resetPassword: ResetPasswordMethod = async (form) => {
  return httpClient.post("/password-reset", form);
};

export const createNewPassword: CreateNewPasswordMethod = async (form) => {
  return httpClient.post("/password-reset-confirm", form);
};

export const resetOtpCode: ResetOtpCodeMethodType = async (form) => {
  return httpClient.post("/reset-otp", form);
};

export const logout: LogooutMethod = async () => {
  return httpClient.get("/user/logout");
};

const methods = {
  signUp,
  signIn,
  activateAccount,
  resetPassword,
  resetOtpCode,
  createNewPassword,
  logout,
};

export type { SignUpForm };

export default methods;
