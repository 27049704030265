import { Box, Skeleton, Container, Stack } from "@mui/material";

const HeaderLoader = () => {
  return (
    <Box position="fixed" top="0" zIndex="100" width="100%">
      <Container sx={{ py: "20px" }}>
        <Stack direction="row" alignItems="center">
          <Skeleton
            variant="rounded"
            sx={{
              height: {
                xs: "29px",
                md: "50px",
                lg: "60px",
              },
              width: {
                xs: "115px",
                lg: "120px",
              },
            }}
          />

          <Skeleton
            variant="rounded"
            sx={{
              height: {
                xs: "29px",
                md: "50px",
                lg: "60px",
              },
              flex: 1,
              mx: "32px",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          />

          <Stack
            direction="row"
            spacing={{
              xs: 2,
              md: 1,
            }}
            ml="auto"
          >
            <Skeleton
              variant="rounded"
              sx={{
                height: {
                  xs: "29px",
                  md: "36px",
                  lg: "60px",
                },
                width: {
                  xs: "29px",
                  md: "36px",
                  lg: "129px",
                },
              }}
            />
            <Skeleton
              variant="rounded"
              sx={{
                height: {
                  xs: "29px",
                  md: "36px",
                  lg: "60px",
                },
                width: {
                  xs: "29px",
                  md: "36px",
                  lg: "178px",
                },
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            />

            <Skeleton
              variant="rounded"
              sx={{
                height: {
                  xs: "29px",
                  md: "36px",
                  lg: "60px",
                },
                width: {
                  xs: "29px",
                  md: "36px",
                  lg: "174px",
                },
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            />

            <Skeleton
              variant="rounded"
              sx={{
                height: {
                  xs: "29px",
                  md: "36px",
                  lg: "60px",
                },
                width: {
                  xs: "29px",
                  md: "36px",
                  lg: "57px",
                },
              }}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default HeaderLoader;
