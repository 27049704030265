"use client";
import React from "react";

import { Box, Typography } from "@mui/material";

import { Button } from "@/shared/components";

import { AuthType } from "../types";

type Props = {
  backToAuthType: AuthType;
  email: string;
  setEmail: (email: string) => void;
  setAuthType: (authType: AuthType) => void;
};

const EmailNotFound = (props: Props) => {
  const { setAuthType, backToAuthType, email } = props;

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        Email is not found
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={1}
      >
        Seems like we don’t have any account associated with this email {email}.
      </Typography>

      <Button sx={{ marginTop: 3 }} onClick={() => setAuthType("signUp")}>
        Create an account
      </Button>

      <Button
        variant="outlined-primary"
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType(backToAuthType)}
      >
        Back to website
      </Button>
    </Box>
  );
};

export default EmailNotFound;
