import { atom, createStore } from "jotai";
import UserApi from "@/shared/api/user";

export type UserType = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  role: "user" | "company_manager";
  job_title?: string;
  company_name?: string;
  status: "active" | "inactive";
};

const IS_AUTH = false;
const EMPTY_USER: UserType = {
  email: "",
  first_name: "",
  id: 0,
  last_name: "",
  role: "user",
  status: "inactive",
};

export type StoreType = {
  user: UserType | null;
  isAuth: boolean;
};

export const fetchUserData = async () => {};

export const store = createStore();

export const isAuth = atom<boolean>(IS_AUTH);
export const userCache = atom<UserType | null>(null);

export const userStore = atom<StoreType>({
  user: EMPTY_USER,
  isAuth: IS_AUTH,
});

export const userData = atom(async (get) => {
  const cache = get(userCache);

  if (cache) {
    return cache;
  }

  try {
    const { data } = await UserApi.getUserInfo();

    store.set(userCache, data);
    store.set(userStore, { user: data, isAuth: true });

    return cache || EMPTY_USER;
  } catch (error) {
    console.error(error);
  }
});

export const clearStore = () => {
  store.set(userStore, { user: EMPTY_USER, isAuth: false });
  store.set(userCache, null);
  store.set(isAuth, false);
};
