"use client";

import { useState } from "react";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import ResetPassword from "./ResetPassword";
import SetNewPassword from "./SetNewPassword";
import Confirm from "./Confirm";
import EmailNotFound from "./EmailNotFound";
import EmailAlreadyRegistrated from "./EmailAlreadyRegistrated";

import { AuthType } from "./types";

type Props = {
  authType: AuthType;
  setAuthType: (authType: AuthType) => void;
  handleClose: () => void;
};

const Auth = (props: Props) => {
  const { authType, setAuthType, handleClose } = props;

  const [email, setEmail] = useState("");
  const [otpCode, setOtpCode] = useState("");

  const params = {
    setAuthType,
  };

  switch (authType) {
    case "signUp":
      return (
        <SignUp
          {...params}
          email={email}
          setEmail={setEmail}
          handleClose={handleClose}
        />
      );
    case "signIn":
      return (
        <SignIn
          {...params}
          email={email}
          setEmail={setEmail}
          handleClose={handleClose}
        />
      );
    case "resetPassword":
      return <ResetPassword {...params} email={email} setEmail={setEmail} />;
    case "setNewPassword":
      return (
        <SetNewPassword
          setAuthType={setAuthType}
          otpCode={otpCode}
          email={email}
          setEmail={setEmail}
        />
      );
    case "signUpConfirmation":
      return (
        <Confirm
          {...params}
          email={email}
          setEmail={setEmail}
          backToAuthType="signUp"
          setOtpCode={setOtpCode}
          title="Confirm registration"
        />
      );
    case "resetConfirmation":
      return (
        <Confirm
          {...params}
          email={email}
          setEmail={setEmail}
          backToAuthType="resetPassword"
          setOtpCode={setOtpCode}
          title="Confirm request"
        />
      );
    case "emailNotFound":
      return (
        <EmailNotFound
          {...params}
          email={email}
          setEmail={setEmail}
          backToAuthType="resetPassword"
        />
      );
    case "EmailAlreadyRegistrated":
      return (
        <EmailAlreadyRegistrated
          {...params}
          setEmail={setEmail}
          email={email}
          title="Email already registered"
        />
      );

    case "ActiveYourAccount":
      return (
        <EmailAlreadyRegistrated
          {...params}
          setEmail={setEmail}
          email={email}
          title="You account is not activeted"
        />
      );
    default:
      return null;
  }
};

export default Auth;
