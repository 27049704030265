"use client";
import { useMediaQuery } from "@mui/material";
import { StoreType } from "@/store/user.store";

import theme from "@/lib/theme";

import DesktopButtons from "./desktop-buttons.component";
import MobileButtons from "./mobile-buttons.component";

type Props = {
  store: StoreType;
  openBurgerMenu: boolean;
  onOpenSearchMenu: () => void;
  onToggleMenu: () => void;
  onOpenModal: () => void;
  onLogout: () => void;
};

const HeaderButtonsComponent = ({
  store,
  openBurgerMenu,
  onOpenSearchMenu,
  onToggleMenu,
  onOpenModal,
  onLogout,
}: Props) => {
  const isMdSize = useMediaQuery<boolean>(theme.breakpoints.up("md"));

  if (isMdSize) {
    return (
      <DesktopButtons
        store={store}
        onOpenModal={onOpenModal}
        onLogout={onLogout}
      />
    );
  }

  return (
    <MobileButtons
      openBurgerMenu={openBurgerMenu}
      onOpenSearchMenu={onOpenSearchMenu}
      onToggleMenu={onToggleMenu}
    />
  );
};

export default HeaderButtonsComponent;
